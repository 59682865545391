const data = [
  {
    id: '1',
    title: 'Naples Vision of Beauty',
    description:
      'Naples Vision Of Beauty is a local medspa located in Naples Florda. I have the pleasure of maintaining the website for them.',
    image: {
      src: 'https://res.cloudinary.com/dvvtmelxa/image/upload/v1689265261/NVOB_touqzu.png',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://naplesvisionofbeauty.com/',
    },
    technologies: [
      '#PHP',
      '#HTML',
      '#CSS',
      '#Shopify',
    ],
  },
  {
    id: '2',
    title: 'Irrigation Specalists',
    description:
      'Irrigation Specalists is a local service company in SWFL. This site was built at Suite Edge',
    image: {
      src: 'https://res.cloudinary.com/dvvtmelxa/image/upload/v1689266612/Irrigation_Specialists_Site_u6zhig.png',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://irrigationspecialistsusa.com/',
    },
    technologies: [
      '#Wordpress',
      '#Formidable Forms',
      '#Divi',
    ],
  },
  {
    id: '3',
    title: 'Stehl Tow',
    description:
      'Stehl Tow is a company based in SC that sells car tow dollys. This site was built at Suite Edge',
    image: {
      src: 'https://res.cloudinary.com/dvvtmelxa/image/upload/v1689266895/Stehl_Tow_Website_de5fom.png',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://stehltow.com/',
    },
    technologies: [
      '#Wordpress',
      '#Formidable Forms',
      '#Divi',
    ],
  },
  {
    id: '4',
    title: 'Done Deal Promo',
    description:
      'Done Deal Promo is a website created for the RNDC company to allow their employees to redeem their points on products of their choosing. This site was built at Suite Edge',
    image: {
      src: 'https://res.cloudinary.com/dvvtmelxa/image/upload/v1689267090/Done_Deal_Promo_cubl3s.png',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://donedealpromo.com/',
    },
    technologies: [
      '#Wordpress',
      '#Formidable Forms',
      '#Divi',
    ],
  },
  {
    id: '5',
    title: 'Land of Milk and Babies',
    description:
      'Land of Milk and Babies is a all in one stop for anything babies. Whether you sign up for a prenatal class or need personal resources. This site was built at Suite Edge',
    image: {
      src: 'https://res.cloudinary.com/dvvtmelxa/image/upload/v1689267392/LOM_B_Website_ujph1g.png',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://landofmilkandbabies.com/',
    },
    technologies: [
      '#Wordpress',
      '#Formidable Forms',
      '#Divi',
    ],
  },
  {
    id: '6',
    title: 'Pathway Property Mgt.',
    description:
      'Pathway Property Management Inc. is an association management company. This site was built at Suite Edge',
    image: {
      src: 'https://res.cloudinary.com/dvvtmelxa/image/upload/v1689268152/Pathway_Property_Mgt_Website_l9udvl.png',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://pathwaypropertymgt.com/',
    },
    technologies: [
      '#Wordpress',
      '#Formidable Forms',
      '#Divi',
    ],
  },
  {
    id: '7',
    title: 'CallXtreme',
    description:
      'CallXtreme is a local company here in SWFL that does carpet & tile cleaning. This site was built at Suite Edge',
    image: {
      src: 'https://res.cloudinary.com/dvvtmelxa/image/upload/v1689268147/Call_Xtreme_Website_g3zodu.png',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://callxtreme.com/',
    },
    technologies: [
      '#Wordpress',
      '#Formidable Forms',
      '#Divi',
    ],
  },
  {
    id: '8',
    title: 'Nationwide Floors Inc',
    description:
      'CallXtreme is a local company here in SWFL that does carpet & tile cleaning. This site was built at Suite Edge',
    image: {
      src: 'https://res.cloudinary.com/dvvtmelxa/image/upload/v1689349052/Nationwide_Floors_Website_bofxcz.png',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://nationwidefloorsinc.com/',
    },
    technologies: [
      '#Wordpress',
      '#Formidable Forms',
      '#Divi',
      '#Smart Slider 3',
    ],
  },
];

export default data;
